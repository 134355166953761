
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ManualOrderTable from '@/components/reports-v2/components/codedWidgets/recommendation/ManualOrderTable.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import gdbx from '@/store/modules/gdbx';
import LastSync from '@/components/LastSync.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import StockListFilter from '@/components/reports-v2/components/filters/StockListFilter.vue';

@Component({
	components: {
		DateRangeFilter,
		LastSync,
		ManualOrderTable,
		SelectFilter,
		StickyFilterContainer,
		StockListFilter,
	},
})
export default class ManualReorder extends Vue {
	public selectedStockItems: string[] = [];

	public selectedDateRange: [number, number] = [
		moment().add(-90, 'days').startOf('day').valueOf(),
		moment().endOf('day').valueOf(),
	];

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment().endOf('day').valueOf();
	}

	public get selectedAgents() {
		return gdbx.allAgents.map((vt) => vt.value);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
